<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Wettelijke vermeldingen
    </h2>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      :schema="schema"
      name="entityLegalForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <template v-if="entity.region !== 1">
        <h2>Watertoets</h2>
        <div class="tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
          <template v-if="showWaterTestInputsPrefix">
            <FormulateInput
              v-for="input in WATER_TEST_INPUTS.prefix"
              :key="input.name"
              v-bind="input"
              disabled
              :input-class="['disabled:tw-bg-gray-cc']"
            />
          </template>
          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 md:tw-col-span-2 xl:tw-col-span-4">
            <FormulateInput
              type="group"
              name="p_scores"
              label="P-scores"
              :repeatable="true"
              add-label="+ P-score toevoegen"
              remove-position="after"
              :group-repeatable-class="['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full']"
              label-class="tw-text-xl tw-font-light"
              outer-class="tw-my-0"
            >
              <template #remove="{ removeItem }">
                <button
                  type="button"
                  title="Verwijderen"
                  class="tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger"
                  @click="removeItem"
                >
                  <i class="far fa-trash" />
                </button>
              </template>
              <div class="tw-flex-grow tw-flex tw-flex-row tw-gap-2">
                <FormulateInput
                  type="select"
                  name="value"
                  label="P-score"
                  :options="['A', 'B', 'C', 'D']"
                  validation="required"
                  outer-class="tw-my-0 tw-flex-shrink-0"
                />
                <FormulateInput
                  type="text"
                  name="plot_number"
                  label="Perceelnummer"
                  placeholder="Perceelnummer"
                  outer-class="tw-my-0 tw-flex-grow"
                />
              </div>
            </FormulateInput>
            <FormulateInput
              type="group"
              name="g_scores"
              label="G-scores"
              :repeatable="true"
              add-label="+ G-score toevoegen"
              remove-position="after"
              :group-repeatable-class="['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end']"
              label-class="tw-text-xl tw-font-light"
              outer-class="tw-my-0"
            >
              <template #remove="{ removeItem }">
                <button
                  type="button"
                  title="Verwijderen"
                  class="tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger"
                  @click="removeItem"
                >
                  <i class="far fa-trash" />
                </button>
              </template>
              <div class="tw-flex-grow tw-flex tw-flex-row tw-gap-2">
                <FormulateInput
                  type="select"
                  name="value"
                  label="G-score"
                  :options="['A', 'B', 'C', 'D']"
                  validation="required"
                  outer-class="tw-my-0 tw-flex-shrink-0"
                />
                <FormulateInput
                  type="text"
                  name="plot_number"
                  label="Gebouw ID"
                  placeholder="Gebouw ID"
                  outer-class="tw-my-0 tw-flex-grow"
                />
              </div>
            </FormulateInput>
          </div>
          <FormulateInput
            v-for="input in WATER_TEST_INPUTS.suffix"
            :key="input.name"
            v-bind="input"
          />
        </div>

        <h2>Onroerend erfgoed</h2>
        <h3 class="tw-italic tw-mt-8">Beschermd onroerend erfgoed</h3>
        <div class="tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
          <FormulateInput
            v-for="input in IMMOVABLE_HERITAGE_INPUTS"
            :key="input.name"
            v-bind="input"
          />
        </div>

        <h3 class="tw-italic">Geïnventariseerd onroerend erfgoed</h3>
          <div class="tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
          <FormulateInput
            v-for="input in IMMOVABLE_HERITAGE_INVENTORY_INPUTS"
            :key="input.name"
            v-bind="input"
          />
        </div>
      </template>

      <FormulateErrors />
      <div class="tw-flex tw-flex-row tw-space-x-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-bg-error tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
          @click="goBack"
        >
          <i class="fa fa-times tw-mr-2"/> Annuleren
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import legalBrussels from '@/forms/schemas/portfolio/legalBrussels'
import { legal, WATER_TEST_INPUTS, IMMOVABLE_HERITAGE_INPUTS, IMMOVABLE_HERITAGE_INVENTORY_INPUTS } from '@/forms/schemas/portfolio/legal'

import { getProjectLegal, updateProjectLegal } from '@/services/projects'
import { getPropertyLegal, updatePropertyLegal } from '@/services/properties'

import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'PortfolioLegalEdit',
  constants: {
    WATER_TEST_INPUTS,
    IMMOVABLE_HERITAGE_INPUTS,
    IMMOVABLE_HERITAGE_INVENTORY_INPUTS
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),

    backRoute () {
      return this.$route.meta.backRoute
    },
    entityId () {
      return this.$route.params.id
    },
    entityType () {
      return this.$route.meta.entity_type
    },
    entity () {
      return this.entityType === 'property'
        ? this.getPropertyById(this.entityId)
        : this.getProjectById(this.entityId)
    },
    schema () {
      return this.entity.region === 1 ? legalBrussels : legal
    },
    showWaterTestInputsPrefix () {
      if (!this.entity?.created_on) return false
      const createdDate = new Date(this.entity.created_on)
      const firstOfJanuary2023 = new Date('2023-01-01')
      return firstOfJanuary2023.getTime() - createdDate.getTime() > 1
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    goBack () {
      this.$router.push(this.backRoute)
    },
    async loadData () {
      try {
        const response = this.entityType === 'property'
          ? await getPropertyLegal(this.entityId)
          : await getProjectLegal(this.entityId)
        this.values = response.data
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityLegalForm')
        errorModal('Fout bij het laden van de wettelijke informatie, probeer het opnieuw.')
      }
    },
    async submit (data) {
      try {
        const response = this.entityType === 'property'
          ? await updatePropertyLegal(this.entityId, data)
          : await updateProjectLegal(this.entityId, data)
        successModal('De wijzigingen aan de wettelijke informatie zijn succesvol opgeslagen')
        this.goBack()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityLegalForm')
        errorModal('Er ging iets fout bij het wijzigen van de wettelijke informatie van dit pand. Gelieve nogmaals te proberen.')
      }
    }
  }
}
</script>
