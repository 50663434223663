export default [
  {
    component: 'h2',
    children: 'Stedenbouwkundige informatie'
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    placeholder: 'Selecteer status',
    options: {
      0: 'Niet ingegeven',
      1: 'In aanvraag',
      2: 'Aanwezig'
    }
  },
  {
    type: 'date',
    name: 'request_date',
    label: 'Datum aanvraag',
    placeholder: 'YYYY-MM-DD',
    validation: 'optional|date:YYYY-MM-DD',
    outerClass: 'tw-m-0'
  }
]
