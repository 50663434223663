var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"tw-mt-2 tw-mb-8"},[_c('router-link',{staticClass:"!tw-text-tg-color hover:tw-opacity-80",attrs:{"to":_vm.backRoute,"title":"Ga terug"}},[_c('i',{staticClass:"fal fa-arrow-left tw-mr-2"})]),_vm._v(" Wettelijke vermeldingen ")],1),_c('FormulateForm',{attrs:{"schema":_vm.schema,"name":"entityLegalForm","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [(_vm.entity.region !== 1)?[_c('h2',[_vm._v("Watertoets")]),_c('div',{staticClass:"tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4"},[(_vm.showWaterTestInputsPrefix)?_vm._l((_vm.WATER_TEST_INPUTS.prefix),function(input){return _c('FormulateInput',_vm._b({key:input.name,attrs:{"disabled":"","input-class":['disabled:tw-bg-gray-cc']}},'FormulateInput',input,false))}):_vm._e(),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 md:tw-col-span-2 xl:tw-col-span-4"},[_c('FormulateInput',{attrs:{"type":"group","name":"p_scores","label":"P-scores","repeatable":true,"add-label":"+ P-score toevoegen","remove-position":"after","group-repeatable-class":['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full'],"label-class":"tw-text-xl tw-font-light","outer-class":"tw-my-0"},scopedSlots:_vm._u([{key:"remove",fn:function(ref){
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger",attrs:{"type":"button","title":"Verwijderen"},on:{"click":removeItem}},[_c('i',{staticClass:"far fa-trash"})])]}}],null,true)},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-row tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"select","name":"value","label":"P-score","options":['A', 'B', 'C', 'D'],"validation":"required","outer-class":"tw-my-0 tw-flex-shrink-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"plot_number","label":"Perceelnummer","placeholder":"Perceelnummer","outer-class":"tw-my-0 tw-flex-grow"}})],1)]),_c('FormulateInput',{attrs:{"type":"group","name":"g_scores","label":"G-scores","repeatable":true,"add-label":"+ G-score toevoegen","remove-position":"after","group-repeatable-class":['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end'],"label-class":"tw-text-xl tw-font-light","outer-class":"tw-my-0"},scopedSlots:_vm._u([{key:"remove",fn:function(ref){
var removeItem = ref.removeItem;
return [_c('button',{staticClass:"tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger",attrs:{"type":"button","title":"Verwijderen"},on:{"click":removeItem}},[_c('i',{staticClass:"far fa-trash"})])]}}],null,true)},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-row tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"select","name":"value","label":"G-score","options":['A', 'B', 'C', 'D'],"validation":"required","outer-class":"tw-my-0 tw-flex-shrink-0"}}),_c('FormulateInput',{attrs:{"type":"text","name":"plot_number","label":"Gebouw ID","placeholder":"Gebouw ID","outer-class":"tw-my-0 tw-flex-grow"}})],1)])],1),_vm._l((_vm.WATER_TEST_INPUTS.suffix),function(input){return _c('FormulateInput',_vm._b({key:input.name},'FormulateInput',input,false))})],2),_c('h2',[_vm._v("Onroerend erfgoed")]),_c('h3',{staticClass:"tw-italic tw-mt-8"},[_vm._v("Beschermd onroerend erfgoed")]),_c('div',{staticClass:"tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4"},_vm._l((_vm.IMMOVABLE_HERITAGE_INPUTS),function(input){return _c('FormulateInput',_vm._b({key:input.name},'FormulateInput',input,false))}),1),_c('h3',{staticClass:"tw-italic"},[_vm._v("Geïnventariseerd onroerend erfgoed")]),_c('div',{staticClass:"tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4"},_vm._l((_vm.IMMOVABLE_HERITAGE_INVENTORY_INPUTS),function(input){return _c('FormulateInput',_vm._b({key:input.name},'FormulateInput',input,false))}),1)]:_vm._e(),_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")]),_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-error tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']},on:{"click":_vm.goBack}},[_c('i',{staticClass:"fa fa-times tw-mr-2"}),_vm._v(" Annuleren ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }