import { YES_NO_REQUEST, YES_NO_NOT_ENTERED } from '@/forms/schemas/generators'

const DESTINATION_TYPES = [
  { value: 1, label: 'Agrarisch gebied' },
  { value: 25, label: 'Bufferzone' },
  { value: 26, label: 'Bestaande waterwegen' },
  { value: 5, label: 'Bosgebied' },
  { value: 23, label: 'Gebieden voor gemeenschapsvoorzieningen en openbaar nut' },
  { value: 2, label: 'Gebied met economische activiteiten' },
  { value: 16, label: 'Gebied voor dagrecreatie' },
  { value: 17, label: 'Gebied voor verblijfsrecreatie' },
  { value: 8, label: 'Gemengd woongebied' },
  { value: 12, label: 'Groengebied' },
  { value: 6, label: 'Industriegebied' },
  { value: 15, label: 'Industriegebied voor ambachtelijke bedrijven of gebieden voor kleine en middelgrote ondernemingen' },
  { value: 0, label: 'In aanvraag' },
  { value: 18, label: 'Landschappelijk waardevol agrarisch gebied' },
  { value: 19, label: 'Natuurreservaat' },
  { value: 9, label: 'Natuurgebied' },
  { value: 20, label: 'Niet ingegeven' },
  { value: 10, label: 'Parkgebied' },
  { value: 7, label: 'Recreatiegebied' },
  { value: 27, label: 'Watergevoelig openruimtegebied' },
  { value: 4, label: 'Woonuitbreidingsgebied' },
  { value: 11, label: 'Woongebied' },
  { value: 13, label: 'Woongebied met een culturele historische en/of esthetische waarde' },
  { value: 21, label: 'Woongebied met recreatief karakter' },
  { value: 22, label: 'Woongebied met landelijk karakter' },
  { value: 14, label: 'Woonpark' },
  { value: 24, label: 'Dienstverleningsgebied' },
  { value: 3, label: 'Winningsgebied' }
]

export const legal = [
  {
    component: 'h2',
    children: 'Stedenbouwkundige vergunning'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'building_permit',
        label: 'Stedenbouwkundige vergunning',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        type: 'translatable',
        name: 'building_permit_info',
        label: 'Stedenbouwkundige vergunning - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  },
  {
    component: 'h2',
    children: 'Stedenbouwkundige bestemming'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        type: 'select',
        name: 'destination_type',
        label: 'Type bestemming',
        placeholder: 'Selecteer bestemming',
        options: DESTINATION_TYPES,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'destination_type_secundairy',
        label: 'Type secundaire bestemming',
        placeholder: 'Selecteer secundaire bestemming',
        options: DESTINATION_TYPES,
        outerClass: 'tw-m-0'
      },
      {
        type: 'translatable',
        name: 'destination_info',
        label: 'Stedenbouwkundige bestemming - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  },
  {
    component: 'h2',
    children: 'Handhaving'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'administrative_order',
        label: 'Bestuursdwang',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        name: 'amicable_settlement',
        label: 'Minnelijke schikking',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        name: 'penalty_payment',
        label: 'Last onder dwangsom',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        name: 'remedial_action',
        label: 'Rechterlijke herstelmaatregel',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        name: 'subpoenas',
        label: 'Dagvaardingen',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        type: 'translatable',
        name: 'subpoenas_info',
        label: 'Handhaving - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  },
  {
    component: 'h2',
    children: 'Voorkooprecht'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children:
    [
      {
        name: 'preemption',
        label: 'Voorkooprecht',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      },
      {
        type: 'translatable',
        name: 'preemption_description',
        label: 'Voorkooprecht - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  },
  {
    component: 'h2',
    children: 'Verkavelingsvergunning'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'allotment_permit',
        label: 'Verkavelingsvergunning',
        outerClass: 'tw-m-0',
        ...YES_NO_REQUEST
      }
    ]
  }
]

export const WATER_TEST_INPUTS = {
  prefix: [
    {
      name: 'flooding_effective',
      label: 'Effectief overstromingsgevoelig',
      outerClass: 'tw-m-0',
      ...YES_NO_NOT_ENTERED
    },
    {
      name: 'flooding_possible',
      label: 'Mogelijk overstromingsgevoelig',
      outerClass: 'tw-m-0',
      ...YES_NO_NOT_ENTERED
    }
  ],
  suffix: [
    {
      name: 'flooding_plain',
      label: 'Afgebakend overstromingsgebied',
      outerClass: 'tw-m-0',
      ...YES_NO_NOT_ENTERED
    },
    {
      name: 'flooding_bank',
      label: 'Afgebakende oeverzone',
      outerClass: 'tw-m-0',
      ...YES_NO_NOT_ENTERED
    },
    {
      name: 'flooding_risk',
      label: 'Risicozone voor overstromingen',
      outerClass: 'tw-m-0',
      ...YES_NO_NOT_ENTERED
    },
    {
      type: 'translatable',
      name: 'flooding_info',
      label: 'Watertoets - extra informatie',
      outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
    }
  ]
}

export const IMMOVABLE_HERITAGE_INPUTS = [
  {
    name: 'heritage_protected_monument',
    label: 'Beschermd monument',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_area',
    label: 'Beschermd landschap',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_cultural_area',
    label: 'Beschermd cultuurhistorisch landschap',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_city',
    label: 'Beschermd stadsgezicht',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_village',
    label: 'Beschermd dorpsgezicht',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_archaeological_site',
    label: 'Beschermde archeologische site',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_archaeological_zone',
    label: 'Beschermde archeologische zone',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_protected_archaeological_monument',
    label: 'Beschermd archeologisch monument',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    type: 'translatable',
    name: 'heritage_info',
    label: 'Onroerend erfgoed - extra informatie',
    outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
  }
]

export const IMMOVABLE_HERITAGE_INVENTORY_INPUTS = [
  {
    name: 'heritage_landscape_atlas',
    label: 'Landschapsatlas',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_inventory_archaeological_zones',
    label: 'Inventaris van archeologische zones',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_inventory_architectural',
    label: 'Inventaris van bouwkundig erfgoed',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_inventory_planting',
    label: 'Inventaris van houtige beplantingen met erfgoedwaarde',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    name: 'heritage_inventory_historical_gardens_parks',
    label: 'Inventaris van historische tuinen en parken',
    outerClass: 'tw-m-0',
    ...YES_NO_NOT_ENTERED
  },
  {
    type: 'translatable',
    name: 'heritage_inventory_info',
    label: 'Geïnventariseerd onroerend erfgoed - extra informatie',
    outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
  }
]
